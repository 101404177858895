'use client';

var __jsx = React.createElement;
import { useHatchBreakpoints } from '@playful/design_system';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';
var WorkbenchPage = dynamic(function () {
  return import('./WorkbenchPage');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('./WorkbenchPage')];
    }
  }
});
var MobileView = dynamic(function () {
  return import('./components/MobileView');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('./components/MobileView')];
    }
  }
});

/**
 * Silly intermediary to decide client-side whether to render the workbench, as using useMediaQuery
 * (what's used under the hood under useHatchBreakpoints) renders `false` on initial render as part
 * of ssr support. as we render this client-side only, we can pass `ssr: false` and be confident we
 * won't load the workbench on initial render.
 */
export function WorkbenchView(props) {
  var _useHatchBreakpoints = useHatchBreakpoints({
      ssr: false
    }),
    isSmallScreen = _useHatchBreakpoints.isSmallScreen;

  // Only show mobile view if we're on a small screen during the first render
  var showMobile = useRef(undefined);
  if (showMobile.current === undefined) {
    //First render, we should show the mobile view if we're on a small screen
    showMobile.current = isSmallScreen;
  } else if (showMobile.current && !isSmallScreen) {
    // If we change to desktop breakpoint, we should always hide the mobile view
    showMobile.current = false;
  }
  if (showMobile.current) {
    return __jsx(MobileView, null);
  } else {
    return __jsx(WorkbenchPage, props);
  }
}