var __jsx = React.createElement;
import Head from 'next/head';
import React from 'react';
export function OpenGraphTwitterMeta(_ref) {
  var ogTitle = _ref.ogTitle,
    ogUrl = _ref.ogUrl,
    _ref$ogImageUrl = _ref.ogImageUrl,
    ogImageUrl = _ref$ogImageUrl === void 0 ? '' : _ref$ogImageUrl;
  return __jsx(Head, null, __jsx("meta", {
    key: "og:title",
    property: "og:title",
    content: ogTitle
  }), __jsx("meta", {
    key: "og:description",
    property: "og:description",
    content: ogTitle
  }), __jsx("meta", {
    key: "og:type",
    property: "og:type",
    content: "website"
  }), __jsx("meta", {
    key: "og:image",
    property: "og:image",
    content: ogImageUrl
  }), __jsx("meta", {
    key: "og:image:width",
    property: "og:image:width",
    content: '1200'
  }), __jsx("meta", {
    key: "og:image:height",
    property: "og:image:height",
    content: '630'
  }), __jsx("meta", {
    key: "og:url",
    property: "og:url",
    content: ogUrl
  }), __jsx("meta", {
    key: "twitter:card",
    name: "twitter:card",
    content: "summary_large_image"
  }), __jsx("meta", {
    key: "twitter:title",
    name: "twitter:title",
    content: ogTitle
  }), __jsx("meta", {
    key: "twitter:site",
    name: "twitter:site",
    content: "@madewithhatch"
  }), __jsx("meta", {
    key: "twitter:image",
    name: "twitter:image",
    content: ogImageUrl
  }));
}